<template>
  <b-card title="Chỉnh sửa bài viết">
    <b-form @submit.prevent="handleSubmit()">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Tiêu đề"
            label-cols-md="2"
            label-for="h-first-name"
          >
            <b-form-input
              id="h-first-name"
              placeholder=""
              v-model="postData.title"
              :class="{ 'is-invalid' : isInvalid(postData.title)}"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Danh mục"
            label-cols-md="2"
            label-for="h-category"
          >
            <b-form-select
              :options="categoryOptions"
              class="mt-1"
              size="sm"
              v-model="postData.category_id"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Sản phẩm"
            label-cols-md="2"
            label-for="product-nksx"
          >
            <b-form-select
              id="product-nksx"
              v-model="postData.summary"
              :options="productOptions"
              class="mt-1"
              size="sm"
              :class="{ 'is-invalid' : isInvalid(postData.summary)}"
            />
          </b-form-group>
        </b-col>
        <b-col v-for="(ele, index) in nksx_arr" :key="index" cols="12">
          <b-card title="Thông tin mốc thời gian:" style="border-style: solid;">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Thời gian"
                    label-cols-md="2"
                    label-for="nksx-timestamp"
                  >
                    <b-form-input
                      id="nksx-timestamp"
                      v-model="ele.timestamp"
                      placeholder="Tháng 10/2021"
                      :class="{ 'is-invalid' : isInvalid(ele.timestamp)}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Tiêu đề"
                    label-cols-md="2"
                    label-for="nksx-title"
                  >
                    <b-form-input
                      id="nksx-title"
                      v-model="ele.title"
                      placeholder="Gieo hạt"
                      :class="{ 'is-invalid' : isInvalid(ele.title)}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Nội dung"
                    label-cols-md="2"
                    label-for="nksx-content"
                  >
                    <b-form-textarea
                      id="nksx-content"
                      v-model="ele.content"
                      placeholder=""
                      rows="10"
                      :class="{ 'is-invalid' : isInvalid(ele.content)}"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Hình ảnh"
                    label-cols-md="2"
                    label-for="nksx-images"
                  >
                    <b-form-textarea
                      id="nksx-images"
                      v-model="ele.images"
                      rows="5"
                      :class="{ 'is-invalid' : isInvalid(ele.images)}"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col offset-md="10">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    variant="danger"
                    @click="nksx_arr.splice(index, 1)"
                  >
                    Xóa mốc thời gian
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="justify-content-end">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1"
          variant="primary"
          @click="nksx_arr.push({
            timestamp: '',
            title: '',
            content: ''
          })"
        >
          Thêm mốc thời gian
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mr-1"
          type="submit"
          variant="primary"
        >
          Lưu thông tin
        </b-button>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BFormSelect,
  BFormTextarea,
} from 'bootstrap-vue'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "../../../../@core/components/toastification/ToastificationContent";

export default {
  name: "NksxEdit",
  directives: {
    Ripple
  },
  components: {
    BButton,
    BFormTextarea,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
  },
  data() {
    return {
      isValidate: false,
      postData: {
        page_id: 'NKSX',
      },
      product: {},
      nksx_arr: [],
    }
  },
  computed: {
    productOptions() {
      return [
        {
          value: this.product.id,
          text: this.product.name,
        }
      ]
    },
    categoryOptions() {
      return [
        {
          value: this.postData.category.id,
          text: this.postData.category.name
        }
      ]
    }
  },
  async created() {
    let id = router.currentRoute.params.id;
    const response = await this.$http.get(`/posts/${id}`);
    if (response.status === 200) {
      this.postData = response.data.data
      this.product = JSON.parse(this.postData.summary)
      this.postData.summary = JSON.parse(this.postData.summary).id
      this.nksx_arr = JSON.parse(this.postData.content)
    }
  },
  methods: {
    isInvalid(val) {
      return this.isValidate && val.length === 0
    },
    handleSubmit () {
      this.isValidate = true
      if (this.isValid()) {
        this.handleRequest()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Thông báo',
            icon: 'BellIcon',
            text: 'Vui lòng nhập đầy đủ thông tin!',
            variant: 'danger',
          },
        })
      }
    },
    isValid() {
      if (this.postData.title.length === 0) return false
      if (this.postData.summary.length === 0) return false
      let res = true
      for (let i = 0; i< this.nksx_arr.length; i++) {
        const e = this.nksx_arr[i]
        if (e.timestamp.length === 0) {
          res = false
          break
        }
        if (e.title.length === 0) {
          res = false
          break
        }
        if (e.content.length === 0) {
          res = false
          break
        }
        if (e.images.length === 0) {
          res = false
          break
        }
      }
      console.log(res)
      return res
    },
    async handleRequest() {
      let id = router.currentRoute.params.id;
      this.postData.content = JSON.stringify(this.nksx_arr)
      let product = this.product
      product.nksx = '{}'
      this.postData.summary = JSON.stringify(product)
      this.$http.put(`/posts/${id}`, this.postData)
        .then(response => {
          this.updateProduct(response.data.data)
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Thông báo',
              icon: 'BellIcon',
              text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
              variant: 'danger',
            },
          })
        })
    },
    async updateProduct(nksx) {
      console.log(nksx)
      let product = this.product
      nksx.summary = ''
      product.nksx = JSON.stringify(nksx)
      this.$http.put(`/products/${product.id}`, product)
          .then(response => {
            console.log(response.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Cập nhật thành công👋',
                variant: 'success',
              },
            })
            this.$router.push('/manage/nksx/list')
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Thông báo',
                icon: 'BellIcon',
                text: 'Đã xảy ra lỗi. Vui lòng thử lại sau!',
                variant: 'danger',
              },
            })
          })
    }
  }
}
</script>

<style scoped>

</style>
